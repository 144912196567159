var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bgRightDP" }, [
    _c("div", { staticClass: "rightChartsContent" }, [
      _c("div", { staticClass: "chartsContent" }, [
        _c("div", { staticClass: "flexItem" }, [_c("filst")], 1),
        _c(
          "div",
          { staticClass: "flexItem" },
          [_c("numberServiceVehicles")],
          1
        ),
        _c("div", { staticClass: "flexItem" }, [_c("serviceIncome")], 1),
        _c("div", { staticClass: "flexItem" }, [_c("status")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }