var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chartsContent-wrap" },
    [
      _c("timeType", {
        attrs: {
          tip: "车场在不同时段累计收入的变化趋势",
          title: "停车收入统计",
        },
        on: { getTimeType: _vm.getTimeType },
      }),
      _c("div", {
        staticClass: "leftThreeChart",
        attrs: { id: "incomeStatistics1" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }