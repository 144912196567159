var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("timeType", { staticClass: "spacing", attrs: { title: "车场收入" } }),
      _c("ytdTime", { on: { getTimeType: _vm.getTimeType } }),
      _c("div", {
        staticClass: "serviceIncome",
        attrs: { id: "serviceIncome" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }