var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chartsContent-wrap" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._v(" 停车周转分析 "),
        _c("tips", {
          attrs: {
            tip: "入口流率：统计范围内路段泊位入口车辆数时变（0-24小时）情况<br/>出口流率：统计范围内路段泊位出口车辆数时变（0-24小时）情况",
          },
        }),
      ],
      1
    ),
    _c("div", {
      staticClass: "turnoverAnlysisDp",
      attrs: { id: "turnoverAnlysisDp" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }